<template>
  <div id="app">
    <img id="logo" alt="Äventyret i Malmö stad" src="./assets/avntyr.png">
    <div id="wrapper">
      <LeaderBoard msg="High score"/>

      
    </div>
  </div>
</template>

<script>
import LeaderBoard from './components/leaderboard.vue'

export default {
  name: 'App',
  components: {
    LeaderBoard
  }
}
</script>

<style>

body {
  margin: 0;
  background: url('assets/background.jpg');
  background-repeat: repeat-y;
  background-size: cover;
  overflow: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Stalinist+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 40px 0 0 0;
  height: 100%;
}

#wrapper {

  display: block;
  margin: auto;
  
 
  height: 100%;
}

#logo {
  width: 400px;
}


</style>
