<template>
  <div class="leaderboard">
    <div id="hs"  v-bind:class="{ showPane: showHigh  }">
    <h2 class="boardTitle">{{ msg }}</h2>
    <ul class="listArenas">

      <li class="arenaItem" v-for="arena in lbData.arenas" :key="arena.title">
        <div class="arenaTitle">{{ arena.title }}</div>
        <ul class="listGames">
          <li class="gameItem" v-for="spel in arena.games" :key="spel">
            <div class="gameTime">{{ spel.gametime }}</div>
            <div class="gameTeam">{{ spel.team }}</div>
          </li>
        </ul>
      </li>
    </ul>
    </div>

   <div id="rg" v-bind:class="{ showPane: showRecent  }">
   <h2 class="boardTitle">Senaste spel</h2>
 <ul class="listArenas">
   <li class="arenaItem">
        <ul class="listGames">
          <li class="gameItem" v-for="rGame in lbData.recent" :key="rGame.finish">
            <div class="gameTitle">{{ rGame.team }}</div>
            <div class="gameTime">{{ rGame.finish }}</div>
            <div class="gameTeam">{{ rGame.title }}</div>
          </li>
        </ul>
      </li>
    </ul>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {

    name: 'LeaderBoard',
    data() {
      return {


        lbData: '',
        showRecent: false,
        showHigh: false,
        pane: 0

      }
    },
    props: {
      msg: String
    },
    methods: {
      getData: function () {
    
        let api = 'https://q.cqs.se/api/games/leaderboard'

        axios.get(api)
          .then(response => {
             
            this.lbData = response.data
          })
          .catch(e => {
            this.errors.push(e)
          })


      },
      rotateDisplay: function () {
        console.log('Roterar')
        if (this.pane < 2) {
          this.pane = this.pane + 1
        } else {
          this.pane = 0
        }
        if (this.pane == 0) {
          this.showHigh = true
          this.showRecent = false
        }
        if (this.pane == 1) {
          this.showRecent = true
          this.showHigh = false
        }
      }
    },

    mounted() {
 

      this.getData()
      this.interval = setInterval(() => this.getData(), 15000)
      this.interval = setInterval(() => this.rotateDisplay(), 25000)
      this.showRecent = true


    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.leaderboard {

  
  max-width: 500px;
  margin: auto;
  display: block;
  padding-top: 20px;
  background: #fbfbfbc3;

}

#hs, #rg {
  display: none;
}

.showPane {
  display: block !important;
  transition: linear;
}

.boardTitle {
  
  color: rgb(49, 49, 49);
}

.listArenas {
  margin: auto;
  max-width: 400px;
 
  padding: 20px 0px 20px 50px;
  list-style: none;
}
.arenaItem {


  width: 100%;
  clear: both;
}
.arenaTitle {
  font-size: 40px;
  font-family: 'Fjalla One', sans-serif;
  text-align: left;


}

.gameTitle {

  padding-top: 8px;
  font-size: 40px;
  font-family: 'Fjalla One', sans-serif;
  text-align: left;
}

.listGames {
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
}

.gameItem {
 
  display: block;
  font-size: 20px;
  font-family: 'Fjalla One', sans-serif;
  clear: both;
}
.gameTeam {
  float: left;
  font-family: 'Fjalla One', sans-serif;
}
.gameTime {
  
  float: left;
  text-align: right;
  margin-right: 15px;

}
</style>