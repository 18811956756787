import { render, staticRenderFns } from "./leaderboard.vue?vue&type=template&id=032a1149&scoped=true&"
import script from "./leaderboard.vue?vue&type=script&lang=js&"
export * from "./leaderboard.vue?vue&type=script&lang=js&"
import style0 from "./leaderboard.vue?vue&type=style&index=0&id=032a1149&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "032a1149",
  null
  
)

export default component.exports